import { Container, Typography, Select, MenuItem, Slider, FormControl, InputLabel, Button } from '@mui/material';

export default function Complete(props) {
  return (
    <Container style={{paddingLeft: 50, paddingRight: 50}}>
        <Typography variant="h6" style={{ flexGrow: 1, marginBottom: 20 }}>
          Complete
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
        Your agreement is complete! Please text us at 714-597-6810 to schedule your class.
        </Typography>
    </Container>
  );
}