import React, { useEffect, useState } from 'react';
import { Container, Typography, Select, MenuItem, Slider, FormControl, InputLabel, Button } from '@mui/material';

import {Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import { API_SERVER, post, query } from './utils';

const stripePromise = loadStripe('pk_live_51MLhgoDRNdBSbovbrpeNSzoXnktb4131h8ciPEpI2xyGlkggX8LAZFXQWnXZmqK7uySDJteUzqNK7ihC6oi5hyr100zsEqhmfQ');
// const stripePromise = loadStripe('pk_test_51MLhgoDRNdBSbovbx9i2V3sNtMKgcyKEEz9fbMqTEiU7W5wwbEWYWcH918SoDTuRGRfuNdmmspHCZRLoScAhj93v00Vwn7Gzo6');
const CheckoutListener = (props) => {
  const [state, setState] = props.state;
  let stripe = useStripe();

  async function fetchSuccess() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (clientSecret && !stripe) return;
  
    if (!clientSecret) return
  
    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(async ({paymentIntent}) => {
        switch (paymentIntent.status) {
          case 'succeeded':
          case 'processing':
            window.history.replaceState({}, document.title, window.location.pathname);
            await post(`${API_SERVER}/payments/completePayment`, {phone: localStorage.getItem('phone')});
            setState('bankaccount');
            // toast.success('Your payment was successful. Your account is now active. You will be taken to the account settings page shortly...');
            // setTimeout(_ => {
            //   location.href = location.protocol + '//' + location.host + '/pages/account-settings/account/'
            // }, 1000);
            break;
  
          case 'requires_payment_method':
            alert(`Your payment was declined, please try again.`);
            window.history.replaceState({}, document.title, window.location.pathname);
            window.location.reload();
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            // toast.error('Your payment failed. Please try another payment method', {});
            break;
  
          default:
            alert(`Something went wrong, please try again.`);
            window.history.replaceState({}, document.title, window.location.pathname);
            window.location.reload();
            // toast.error('Something went wrong. Please try again.');
            break;
        }
      });
  }

  useEffect(_ => {
    fetchSuccess();
  }, [stripe]);

  return (
    <Typography sx={{ color: 'text.disabled' }}>
    </Typography>
  )
};

const CheckoutElement = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [processing, setProcessing] = useState(false);
  
    async function submitCC() {
      setProcessing(true);
      const {error} = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: window.location.protocol + '//' + window.location.host,
        },
      });
  
      if (error) {
        setErrorMessage(error.message);
      }
  
      setProcessing(false);
  
    }
  
    return (
      <>
        <Typography variant="h6" gutterBottom>
        Link bank account for ${props.terms.monthlyPayment} monthly payment ({props.terms.months} months)
        </Typography>
        <PaymentElement />
        <Button hidden={processing} variant='contained' onClick={submitCC} sx={{ mt: 3 }}>
        Submit Payment
        </Button>
        {errorMessage && <div>{errorMessage}</div>}
      </>
    )
  };

export default function MonthlyPayment(props) {
    const [token, setToken] = useState();
    const [terms, setTerms] = useState();
    const [queryString, setQueryString] = useState(0);

    async function createToken() {
        if (!terms) return;
        let req = await post(`${API_SERVER}/payments/createIntent`, {phone: localStorage.getItem('phone'), amount: terms.monthlyPayment, ach: true});
        let {intent} = await req.json();
        setToken(intent);
    }

    useEffect(_ => {
        setTerms(JSON.parse(localStorage.getItem('paymentTerms')));
    }, []);

    useEffect(_ => {
        if (query('payment_intent_client_secret')) setQueryString(Math.random());
        else createToken();
    }, [terms]);

    return (
        <Container>
            { token ? (
          <Elements stripe={stripePromise} options={{
            clientSecret: token,
          }}>
            <CheckoutElement terms={terms} />
          </Elements>
          ) : 
            null
          }

        {queryString &&
          <Elements stripe={stripePromise}>
            <CheckoutListener state={props.state} />
          </Elements>
          }
    </Container>
    )
}