import React, { useEffect, useState } from 'react';
import { Container, Typography, Select, MenuItem, Slider, FormControl, InputLabel, Button } from '@mui/material';
import { query, setProgess } from './utils';

export default function PaymentTerms(props) {
  const [state, setState] = props.state;
  const [days, setDays] = useState(5);
  const [downPayment, setDownPayment] = useState(25);
  const [months, setMonths] = useState(12);
  const [downAmount, setDownAmount] = useState(0);
  const [monthlyAmount, setMonthlyAmount] = useState(0);
  const [customPayment, setCustomPayment] = useState(false);

  useEffect(_ => {
    let total = customPayment ? customPayment : (days * 300);
    let down = Math.round(total * (downPayment/100));
    let monthly = Math.round((total - down) / months); 
    setDownAmount(down);
    setMonthlyAmount(monthly);

    props.terms[1](prevState => ({
      ...prevState,
      downPayment: down,
      monthlyPayment: monthly,
      months: months,
      days: days,
      total: total
    }));

  }, [days, downPayment, months]);

  useEffect(_ => {
    if (query('ag')) {
      setCustomPayment(Number(query('ag')));
    }
  }, []);

  useEffect(_ => {
    setDays(Number(query('d')));
  }, [customPayment]);

  const renderDetails = _ => {
    if (downAmount == 0) return null;

    if (downPayment == 100) {
        return (<>
            <Typography gutterBottom>${downAmount} paid in full today.</Typography>
        </>)
    }

    if (localStorage.getItem('odp') == 'yes') {
      return (<>
        <Typography gutterBottom>${downAmount} paid deposit paid today.</Typography>
    </>)
    }

    return (<>
        <Typography gutterBottom>Payment Details: ${downAmount} today, ${monthlyAmount} per month for {months} months</Typography>
    </>);
  };

  return (
    <Container style={{paddingLeft: 50, paddingRight: 50}}>
        <Typography variant="h6" style={{ flexGrow: 1, marginBottom: 20 }}>
          Payment Terms
        </Typography>

      {customPayment ? 
      <>
      <Typography style={{marginBottom: 20}}>Custom Payment: ${customPayment}</Typography>
      </> :
      <>
      <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
        <InputLabel>Days</InputLabel>
        <Select
          value={days}
          onChange={(e) => setDays(e.target.value)}
          label="Days"
        >
          <MenuItem value={3}>3 days ($900)</MenuItem>
          <MenuItem value={4}>4 days ($1200)</MenuItem>
          <MenuItem value={5}>5 days ($1500)</MenuItem>
        </Select>
      </FormControl>
      </> }

      <Typography gutterBottom>Down Payment ({downPayment}%)</Typography>
      <Slider
        value={downPayment}
        onChange={(e, newValue) => setDownPayment(newValue)}
        step={5}
        marks
        min={25}
        max={100}
        valueLabelDisplay="auto"
      />

    {downPayment != 100 && localStorage.getItem('odp') != 'yes' && (<>
      <Typography gutterBottom>Number of Months ({months})</Typography>
      <Slider
        value={months}
        onChange={(e, newValue) => setMonths(newValue)}
        step={1}
        marks
        min={2}
        max={12}
        valueLabelDisplay="auto"
      />
      </>)}

        {renderDetails()}

        <Button variant='contained' onClick={_ => {
          setState('downpayment')
          setProgess('downpayment', {paymentTerms: props.terms[0]});
        }} sx={{ mt: 3 }}>Confirm</Button>
    </Container>
  );
}