import * as React from 'react';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import { setProgess } from './utils';

export default function AddressForm(props) {
  const [formValues, setFormValues] = props.info;
  const [state, setState] = props.state;

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let tempErrors = {};

    tempErrors.firstName = formValues.firstName ? "" : "First name is required.";
    tempErrors.lastName = formValues.lastName ? "" : "Last name is required.";
    tempErrors.address1 = formValues.address1 ? "" : "Address line 1 is required.";
    tempErrors.city = formValues.city ? "" : "City is required.";
    tempErrors.state = formValues.state ? "" : "State is required.";
    tempErrors.zip = formValues.zip ? "" : "Zip / Postal code is required.";

    // Phone number validation for U.S. numbers
    tempErrors.phone = formValues.phone && /^(?:(?:\+1\s?)?\(?([2-9][0-8][0-9])\)?[-. ]?)?([2-9][0-9]{2})[-. ]?([0-9]{4})$/.test(formValues.phone) ? "" : "Invalid phone number.";

    setErrors(tempErrors);

    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("Form is valid. Submitting...");
      // Handle form submission logic here
      setProgess('terms');
      setState('terms');
      props.submit();
    } else {
      console.log("Form has errors.");
    }
  };

  return (
    <Container style={{ paddingLeft: 50, paddingRight: 50 }}>
      <Typography variant="h6" gutterBottom>
        Your Information
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              label="First name"
              fullWidth
              autoComplete="given-name"
              variant="standard"
              value={formValues.firstName}
              onChange={handleInputChange}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              label="Last name"
              fullWidth
              autoComplete="family-name"
              variant="standard"
              value={formValues.lastName}
              onChange={handleInputChange}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="address1"
              name="address1"
              label="Address line 1"
              fullWidth
              autoComplete="shipping address-line1"
              variant="standard"
              value={formValues.address1}
              onChange={handleInputChange}
              error={Boolean(errors.address1)}
              helperText={errors.address1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="address2"
              name="address2"
              label="Address line 2"
              fullWidth
              autoComplete="shipping address-line2"
              variant="standard"
              value={formValues.address2}
              onChange={handleInputChange}
              // No validation for address2 since it's optional
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="city"
              name="city"
              label="City"
              fullWidth
              autoComplete="shipping address-level2"
              variant="standard"
              value={formValues.city}
              onChange={handleInputChange}
              error={Boolean(errors.city)}
              helperText={errors.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="state"
              name="state"
              label="State"
              fullWidth
              variant="standard"
              value={formValues.state}
              onChange={handleInputChange}
              error={Boolean(errors.state)}
              helperText={errors.state}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="zip"
              name="zip"
              label="Zip / Postal code"
              fullWidth
              autoComplete="shipping postal-code"
              variant="standard"
              value={formValues.zip}
              onChange={handleInputChange}
              error={Boolean(errors.zip)}
              helperText={errors.zip}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="phone"
              name="phone"
              label="Phone Number"
              fullWidth
              autoComplete="phone"
              variant="standard"
              type="tel"
              value={formValues.phone}
              onChange={handleInputChange}
              error={Boolean(errors.phone)}
              helperText={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
