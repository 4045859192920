import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, Select, MenuItem, Slider, FormControl, InputLabel, Button } from '@mui/material';
import { API_SERVER, post, setProgess } from './utils';
import html2canvas from "html2canvas"
import SignatureCanvas from 'react-signature-canvas'

export default function Agreement(props) {
    const exportRef = useRef();
    const [paymentTerms, setPaymentTerms] = useState({});
    const [state, setState] = props.state;
    const [monthlyPayments, setMonthlyPayments] = useState(true);
    const [drawn, setDrawn] = useState(false);

    async function upload() {
        let el = exportRef.current;
        let canvas = await html2canvas(el);
        if (canvas.width > 1280) {
            let aspect = canvas.width/canvas.height;
            let canvas2 = document.createElement('canvas');
            canvas2.width = 1280;
            canvas2.height = 1280 / aspect;
            canvas2.getContext('2d').drawImage(canvas, 0, 0, canvas2.width, canvas2.height);
            canvas = canvas2;
        }
        let data = canvas.toDataURL('jpg');
        await post(`${API_SERVER}/api/agreement`, {phone: localStorage.getItem('phone'), image: data});
    }

    async function onComplete() {
        setDrawn(false);
        await upload();
        setState('complete')
        setProgess('complete');
    }

    useEffect(_ => {
        let pt = JSON.parse(localStorage.getItem('paymentTerms'));
        if (pt?.monthlyPayment == 0) setMonthlyPayments(false);
        setPaymentTerms(pt || {});
    }, []);

  return (
    <Container style={{paddingLeft: 50, paddingRight: 50}} ref={exportRef}>
        <Typography variant="h6" style={{ flexGrow: 1, marginBottom: 20 }}>
          Agreement
        </Typography>

        {monthlyPayments ? (<>
        <Typography style={{textAlign: 'left', marginBottom: 20}}>
        This is a payment plan, not a financing arrangement. No interest or financing fees apply. I agree to pay the total amount of ${paymentTerms.total} by paying ${paymentTerms.downPayment} down and ${paymentTerms.monthlyPayment} per month for ${paymentTerms.months} months.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
        The down payment has been made and is non-refundable.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
        You permit Aura Premium Vinyl LLC to auto-debit your bank account starting one month from today until the balance is cleared.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
        A $25 fee applies for each failed ACH bank debit.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
        Multiple unsuccessful payment attempts will lead to account handover to collections, potentially affecting your credit score.
        </Typography>

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
        You may cancel your class ahead of time and avoid monthly payments, but the deposit is non-refundable. By attending the class, monthly payments become non-cancellable. Not attending the class without prior notice of cancellation does not nullify this agreement and you will still be responsible for the entire balance.
        </Typography>
        </>) : null}

        <Typography style={{textAlign: 'left', marginBottom: 20}}>
        Aura Premium Vinyl LLC offers vinyl wrap training. All payments for this service are non-refundable.
        </Typography>

        <SignatureCanvas penColor='black'
              onEnd={_ => setDrawn(true)}
              canvasProps={{width: 300, height: 100, className: 'sigCanvas'}} />

        <Typography>Sign your name above</Typography>

        <Button variant='contained' onClick={onComplete} disabled={!drawn} sx={{ mb: 10, mt: 3 }}>AGREE</Button>
    </Container>
  );
}