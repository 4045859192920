export const post = (url, data) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

export const API_SERVER = (function() {
    if (typeof window === 'undefined') return 'http://192.168.1.152:5003';
    if (window.location.hostname.includes('auravinyl')) {
      return 'https://payments.auravinyl.com'
    }
  
    // return 'https://shop-services.herokuapp.com';
    return 'http://localhost:5002';
  })();


export const query = (key, value) => {
    let _searchParams = new URLSearchParams(window.location.search);
    if (_searchParams) {
        value = _searchParams.get(key);
        if (value === '0') value = 0;
        else if (value === 'false' || value === null) value = false;
        else if (value === '') value = true;
    } else {
        let escapedKey = encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&');
        value = decodeURIComponent(window.location.search.replace(new RegExp(`^(?:.*?[&?]${escapedKey}(?:\=([^&]*)|[&$]))?.*$`, 'i'), '$1'));
        if (value == '0') {
            value = 0;
        } else if (value == 'false') {
            value = false;
        } else if (!value.length) {
            value = new RegExp(`[&?]${escapedKey}(?:[&=]|$)`, 'i').test(window.location.search);
        }
    }
    return value;
}

export const setProgess = async (value, data = {}) => {
    if (localStorage.getItem('phone')) {
        if (value == 'monthlyPayment') data.paidDeposit = 'yes';
        if (value == 'agreement') data.linkedBank = 'yes';
        let req = await post(`${API_SERVER}/api/update`, {phone: localStorage.getItem('phone'), data, status: value});
        let {user} = await req.json();
        loadProgress(user);
    }
    if (value != 'downpayment') {
        localStorage.setItem('currentState', value);
    }
}

export const loadProgress = async data => {
    if (data) {
        for (let key in data) {
            let obj = data[key];
            if (typeof obj === 'object') obj = JSON.stringify(obj);
            localStorage.setItem(key, obj);
        }
    } else {
        if (localStorage.getItem('phone')) {
            let req = await post(API_SERVER+'/api/createUser', {phone: localStorage.getItem('phone')});
            let {user} = await req.json();
            loadProgress(user);
        }
    }
}